import { createRouter, createWebHistory, RouteRecordRaw, useRoute, RouteLocationNormalized } from 'vue-router';
import MineView from '@/views/mine/index.vue';
import i18n from '@/i18n';
import { authStore, settingStore } from '@/stores';
import urlJoin from 'url-join';
import CustomPageView from '@/views/custom-page-view.vue';
import { loginGoBack } from '@/utils';
import qs from 'qs';
import { defineComponent, computed } from 'vue';

const t = i18n.global.t;

const CommonLayout = defineComponent({
  setup() {
    const route = useRoute();
    const key = computed(() => `${route.path}?${qs.stringify(route.query)}`);
    return {
      key
    };
  },
  template: '<router-view :key="key" />'
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: require('@/layouts/app-layout.vue').default,
    children: [
      {
        path: '',
        component: CommonLayout,
        children: [
          {
            path: '',
            name: 'home',
            component: CustomPageView,
            props: {
              slug: 'home'
            }
          },
          {
            path: '/terracycle_intro',
            name: 'terracycleIntro',
            component: CustomPageView,
            props: {
              slug: 'terracycle_intro'
            }
          },
          {
            path: '/strategic_partners',
            name: 'strategicPartners',
            component: CustomPageView,
            props: {
              slug: 'strategic_partners'
            }
          },
          {
            path: '/story',
            component: {
              template: '<router-view />'
            },
            children: [
              {
                path: '',
                name: 'story.index',
                component: require('@/views/story/index.vue').default
              },
              {
                path: ':slug',
                name: 'story.detail',
                component: require('@/views/story/detail.vue').default
              },
            ]
          },
          {
            path: '/mine',
            name: 'mine',
            component: MineView,
            redirect: { name: 'mine.memberProfile' },
            children: [
              {
                path: 'member_profile',
                name: 'mine.memberProfile',
                component: require('@/views/mine/member-profile.vue').default,
                meta: {
                  title: t('Member profile')
                }
              },
              {
                path: 'member_profile/edit',
                name: 'mine.memberProfile.edit',
                component: require('@/views/mine/member-profile-edit.vue').default,
                meta: {
                  title: t('Member profile')
                }
              },
              {
                path: 'member_profile/delete',
                name: 'mine.memberProfile.delete',
                component: require('@/views/mine/delete-account.vue').default,
                meta: {
                  title: t('Member profile')
                }
              },
              {
                path: 'tc_point',
                name: 'mine.tc_point',
                component: require('@/views/mine/tc-point.vue').default,
                meta: {
                  title: t('TC Point')
                }
              },
              {
                path: 'program_participation_record',
                name: 'mine.programParticipationRecord',
                component: require('@/views/mine/program-participation-record.vue').default,
                meta: {
                  title: t('Program participation record')
                }
              },
              {
                path: 'program_participation_record/:id',
                name: 'mine.programParticipationDetail',
                component: require('@/views/mine/program-participation-detail.vue').default,
                meta: {
                  title: t('Detail')
                }
              },
              {
                path: 'membership_id_with_qrcode',
                name: 'mine.membershipIdWithQrcode',
                component: require('@/views/mine/membership-id-with-qrcode.vue').default,
                meta: {
                  title: t('Membership ID with QR code')
                }
              },
              {
                path: 'membership_grade',
                name: 'mine.membershipGrade',
                component: require('@/views/mine/membership-grade.vue').default,
                meta: {
                  title: t('Membership grade')
                }
              },
              {
                path: 'shop',
                name: 'mine.shop',
                component: require('@/views/mine/shop.vue').default,
                children: [
                  {
                    path: 'wish_list',
                    name: 'mine.shop.wishList',
                    component: require('@/views/mine/wish-list.vue').default,
                    meta: {
                      title: t('Wish list')
                    }
                  },
                  {
                    path: 'my_orders',
                    name: 'mine.shop.myOrders',
                    component: require('@/views/mine/my-orders.vue').default,
                    meta: {
                      title: t('My orders')
                    }
                  },
                  {
                    path: 'my_orders/:id',
                    name: 'mine.shop.myOrders.detail',
                    component: require('@/views/mine/my-orders-detail.vue').default,
                    meta: {
                      title: t('My orders detail')
                    }
                  },
                ]
              },
              {
                path: 'qrcode',
                name: 'mine.qrcode',
                component: require('@/views/mine/qrcode.vue').default,
                meta: {
                  title: t('My QR code')
                }
              },
              {
                path: 'offline_collections',
                name: 'mine.offline_collections',
                component: require('@/views/offline-collections/store-person-views/list.vue').default,
                meta: {
                  title: t('Incentive Campaign List')
                }
              },
              {
                path: 'offline_collections/:id',
                name: 'mine.offline_collections.detail',
                component: require('@/views/offline-collections/store-person-views/detail.vue').default,
                meta: {
                  customBreadcurmb: true
                }
              },
              {
                path: 'offline_collections/:id/new',
                name: 'mine.offline_collections.detail.new',
                component: require('@/views/offline-collections/store-person-views/submit.vue').default,
                meta: {
                  title: t('New')
                }
              },
              {
                path: 'offline_collections/:id/submission',
                name: 'mine.offline_collections.detail.submission',
                component: require('@/views/offline-collections/store-person-views/submit-success.vue').default,
                meta: {
                  title: t('Submission')
                }
              },
              {
                path: 'coupons',
                name: 'mine.coupons',
                component: require('@/views/offline-collections/coupon-views/list.vue').default,
                meta: {
                  title: t('My Coupon')
                }
              },
              {
                path: 'coupons/:id',
                name: 'mine.coupon',
                component: require('@/views/offline-collections/coupon-views/detail.vue').default,
                meta: {
                  title: t('My Coupon')
                }
              },
              {
                path: 'oc_user_requests/:id',
                name: 'oc_user_requests.detail',
                component: require('@/views/offline-collections/history.vue').default,
                meta: {
                  title: t('offline_collection.oc_collection_history')
                }
              },
              {
                path: 'programs/:id/oc_qrcode',
                name: 'program.oc_qrcode',
                component: require('@/views/offline-collections/qrcode.vue').default,
                meta: {
                  title: t('My QR Code')
                }
              },
              {
                path: 'pdo',
                component: {
                  template: '<router-view />'
                },
                children: [
                  {
                    path: 'programs',
                    name: 'pdo.programs',
                    component: require('@/views/pdo/program-list.vue').default,
                    meta: {
                      title: t('PDO Dashboard')
                    }
                  },
                  {
                    path: 'programs/:id',
                    name: 'pdo.program',
                    component: require('@/views/pdo/program-detail.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                  {
                    path: 'programs/:id/bins',
                    name: 'pdo.bin-list',
                    component: require('@/views/pdo/bin-list.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                  {
                    path: 'programs/:id/bins/new',
                    name: 'pdo.bin-new',
                    component: require('@/views/pdo/bin-new.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                  {
                    path: 'programs/:id/submissions',
                    name: 'pdo.submission-list',
                    component: require('@/views/pdo/submission-list.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                  {
                    path: 'programs/:id/submissions/new',
                    name: 'pdo.submission-new',
                    component: require('@/views/pdo/submission-new.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                  {
                    path: 'programs/:id/submissions/new_submitted',
                    name: 'pdo.submission-new-submitted',
                    component: require('@/views/pdo/submission-new-submitted.vue').default,
                    meta: {
                      customBreadcurmb: true
                    }
                  },
                ]
              },
              {
                path: 'tc_subscribe',
                name: 'mine.tcSubscribe',
                component: require('@/views/mine/tc-subscribe.vue').default,
                meta: {
                  title: t('TC Subscribe')
                }
              },
            ]
          },
          {
            path: '/login',
            component: { template: '<router-view/>' },
            children: [
              {
                path: '',
                name: 'login',
                component: require('@/views/login/index.vue').default,
              },
              {
                path: 'forget_password',
                name: 'forgetPassword',
                component: require('@/views/login/forget-password.vue').default,
              },
              {
                path: 'callback_tc',
                name: 'login.tc',
                component: require('@/views/login/callback.vue').default,
              },
              {
                path: 'callback_kakao',
                name: 'login.kakao',
                component: require('@/views/login/callback.vue').default,
              },
              {
                path: 'callback_naver',
                name: 'login.naver',
                component: require('@/views/login/callback.vue').default,
              },
              {
                path: 'callback_google',
                name: 'login.google',
                component: require('@/views/login/callback.vue').default,
              },
              {
                path: 'callback_line',
                name: 'login.line',
                component: require('@/views/login/callback.vue').default,
              }
            ]
          },
          {
            path: 'find_id',
            name: 'find_id',
            component: require('@/views/find-id.vue').default
          },
          {
            path: '/custom_pages/:slug',
            name: 'customPage',
            component: CustomPageView,
          },
          {
            path: '/editor/custom_pages/:slug',
            name: 'editor.customPage',
            component: require('@/views/editor/custom-page-view.vue').default
          },
          {
            path: '/programs',
            name: 'programs.index',
            component: require('@/views/programs/index-view.vue').default
          },
          {
            path: '/all_programs',
            name: 'programs.all',
            component: require('@/views/programs/all-view.vue').default
          },
          {
            path: '/programs/:slug',
            name: 'programs.show',
            component: CustomPageView,
          },
          {
            path: '/static_pages/:slug',
            name: 'staticPage',
            component: require('@/views/static-page-view.vue').default
          },
          {
            path: ':pathMatch(.*)',
            name: '404',
            component: require('@/views/404.vue').default
          },
          {
            path: '/media_room',
            name: 'mediaRoom',
            component: require('@/views/media-room/index.vue').default
          },
          {
            path: '/points',
            name: 'points',
            component: require('@/views/points/index.vue').default
          },
          {
            path: '/charity/new',
            name: 'charity.new',
            component: require('@/views/charity/form.vue').default
          },
          {
            path: '/business_inquiry',
            name: 'business_inquiry',
            component: require('@/views/business-inquiry.vue').default
          },
          {
            path: '/inquiry_to_use',
            name: 'inquiry_to_use',
            component: require('@/views/inquiry-to-use.vue').default
          },
          {
            path: '/contact_us',
            name: 'contactUs',
            component: require('@/views/contact-us.vue').default
          },
          {
            path: '/search',
            name: 'search',
            component: require('@/views/search-view.vue').default
          },
          {
            path: '/signup',
            name: 'signup',
            component: require('@/views/signup/index.vue').default
          }
        ]
      },
      {
        path: '/faq',
        component: require('@/views/faq/layouts/layout-index.vue').default,
        children: [
          {
            path: '',
            name: 'faq.index',
            component: require('@/views/faq/index.vue').default
          },
          {
            path: 'search',
            name: 'faq.search',
            component: require('@/views/faq/search.vue').default
          },
          {
            path: 'sections/:id',
            component: require('@/views/faq/layouts/layout-section.vue').default,
            children: [
              {
                path: '',
                name: 'faq.section',
                component: require('@/views/faq/section.vue').default,
              },
              {
                path: 'articles/:slug',
                name: 'faq.article',
                component: require('@/views/faq/article.vue').default
              }
            ]
          }
        ]
      },
      {
        path: '/shop',
        component: require('@/layouts/shop-layout.vue').default,
        children: [
          {
            path: '',
            name: 'shop.index',
            component: require('@/views/shops/index.vue').default
          },
          {
            path: 'shopping-cart',
            name: 'shop.shopping_cart',
            component: require('@/views/shops/shopping-cart-view/index.vue').default,
          },
          {
            path: 'variants',
            name: 'variant.list',
            component: require('@/views/shops/variant-list-view.vue').default,
          },
          {
            path: 'variants/:id',
            name: 'variant.detail',
            component: require('@/views/shops/variant-detail-view/index.vue').default
          }
        ]
      },
      {
        path: 'checkout/:ids',
        component: require('@/layouts/checkout-layout.vue').default,
        children: [
          {
            path: '',
            name: 'checkout.home',
            component: require('@/views/checkout/index').default
          },
          {
            path: 'addresses/:id/edit',
            name: 'checkout.edit_shipping_address',
            component: require('@/views/checkout/shipping-address-form-view.vue').default
          },
          {
            path: 'addresses/new',
            name: 'checkout.create_shipping_address',
            component: require('@/views/checkout/shipping-address-form-view.vue').default
          }
        ]
      },
      {
        path: '/offline_collections/:id/bins/:binId',
        component: require('@/layouts/collection-bin-layout.vue').default,
        children: [
          {
            path: '',
            name: 'offline_collections.bin.index',
            component: require('@/views/offline-collections/bin-views/index.vue').default
          },
          {
            path: 'submit',
            name: 'offline_collections.bin.submit',
            component: require('@/views/offline-collections/bin-views/submit.vue').default
          },
          {
            path: 'user_requests/:userRequestId/request_reward',
            name: 'offline_collections.bin.request_reward',
            component: require('@/views/offline-collections/bin-views/request-reward.vue').default
          },
          {
            path: 'user_requests/:userRequestId/request_reward_finish',
            name: 'offline_collections.bin.request_reward_finish',
            component: require('@/views/offline-collections/bin-views/request-rewords-finish.vue').default
          }
        ]
      },
      {
        path: '/programs',
        component: {
          template: '<router-view />'
        },
        children: [
          {
            path: ':id/apply-to-be-pdo',
            component: require('@/views/pdo/layouts/apply-to-be-pdo.vue').default,
            children: [
              {
                path: 'form',
                name: 'pdo.apply-to-be-form',
                component: require('@/views/pdo/apply-to-be-form.vue').default
              },
              {
                path: 'form-submitted',
                name: 'pdo.apply-to-be-form-submitted',
                component: require('@/views/pdo/apply-to-be-submitted.vue').default
              }
            ]
          },
        ]
      },
      {
        path: '/pdos/:id/bins/:binId',
        component: require('@/layouts/collection-bin-layout.vue').default,
        children: [
          {
            path: '',
            name: 'pdo.bin.form',
            component: require('@/views/pdo/self-recycling-form.vue').default
          },
          {
            path: 'user_requests/:userRequestId/form-submitted',
            name: 'pdo.bin.form-submitted',
            component: require('@/views/pdo/self-recycling-submitted.vue').default
          }
        ]
      }
    ]
  },
  {
    path: '/partner_sso/:token',
    name: 'partner_sso',
    component: require('@/views/partner-sso.vue').default
  },
  {
    path: '/emart/:id/academy',
    name: 'emart.academy',
    component: require('@/templates/emart/academy/index.vue').default
  },
  {
    path: '/emart/shops',
    name: 'emart.shops',
    component: require('@/templates/emart/shops/index.vue').default
  },
  {
    path: '/editor/snippet',
    name: 'editor.snippet',
    component: () =>
      import(/* webpackChunkName: "sinippet-view" */ '@/views/editor/snippet-view.vue'),
  },
  process.env.VUE_APP_ENV !== 'production' && {
    path: '/templates',
    component: { template: '<router-view/>' },
    children: [
      {
        path: 'coca-cola',
        name: 'templates.cocaCola',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/compaign-coca-cola/index.vue')
      },
      {
        path: 'huggies',
        name: 'templates.huggies',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/compaign-huggies/index.vue')
      },
      {
        path: 'emart',
        name: 'templates.emart',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/emart/index.vue')
      },
      {
        path: 'emart_programs/1',
        name: 'templates.emartProgram1',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/emart/program-1.vue')
      },
      {
        path: 'emart_programs/2',
        name: 'templates.emartProgram2',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/emart/program-2.vue')
      },
      {
        path: 'emart_programs/3',
        name: 'templates.emartProgram3',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/emart/program-3.vue')
      },
      {
        path: 'emart_programs/4',
        name: 'templates.emartProgram4',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/emart/program-4.vue')
      },
      {
        path: 'on-going-program',
        name: 'templates.onGoingProgram',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/on-going-program/index-view.vue')
      },
      {
        path: 'ssg',
        name: 'templates.ssg',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/campaign-ssg/index.vue')
      },
      {
        path: 'cocacola',
        name: 'templates.cocacola',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/campaign-cocacola-season-4/index.vue')
      },
      {
        path: 'cocacola-s5',
        name: 'templates.cocacolas5',
        component: () => import(/* webpackChunkName: "templates-view" */ '@/templates/campaign-cocacola-season-5/index.vue')
      }
    ]
  }
].filter(Boolean) as Array<RouteRecordRaw>;

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTER_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // 不影响hash跳转
      const getPathWithQuery = (raw: RouteLocationNormalized) => raw.fullPath.split('#')[0];
      if (getPathWithQuery(to) !== getPathWithQuery(from)) {
        return { top: 0 };
      }
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // 如果已登录，再进入登录或注册页面，直接跳走
  if (['login', 'signup', 'forgetPassword'].includes(to.name as string)) {
    if (to.name === 'login') {
      // 第三方网站点击登录跳回tc域名进行登录
      if (location.origin !== process.env.VUE_APP_SITE_ORIGIN && process.env.VUE_APP_ENV !== 'development') {
        location.href = urlJoin(process.env.VUE_APP_SITE_ORIGIN, to.fullPath);
      }
    }

    // 先进入页面，确保页面正常加载
    next();
    // 使用 settingStore.tryFetchData 确保直接通过链接进入时，能准确获取到登录状态
    await settingStore.tryFetchData();
    if (authStore.isLogin) {
      await loginGoBack({ route: to });
    }
    return;
  } else if (location.origin !== process.env.VUE_APP_SITE_ORIGIN && process.env.VUE_APP_ENV !== 'development') {
    // 通过 https://www.onethepl.co.kr 进入首页，需要重定向到 '/programs/onethepl'
    const redirectOrigin = {
      'www.onethepl.co.kr': '/programs/onethepl'
    };
    let fullPath = (location.host + location.pathname).replace(/\/$/, '');
    if (Object.hasOwnProperty.call(redirectOrigin, fullPath)) {
      next(redirectOrigin[fullPath]);
      return;
    }
    // 判断自定义域名
    // 以下页面才可以通过自定义域名进入
    const customDomainPages = ['programs.show', 'emart.academy'];
    if (customDomainPages.includes(to.name as string)) {
      next();
      return;
    } else {
      next(false);
      location.href = urlJoin(process.env.VUE_APP_SITE_ORIGIN, to.fullPath);
      return;
    }
  } else {
    // 自定义页面编辑页面/代码片段编辑页面 不能跳到别的页面
    const fromName = from.name || '';
    next(!(fromName as string).includes('editor.'));
    return;
  }
});

export default router;
