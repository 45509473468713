export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Please confirm you are 14 years of age or older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm you are 14 years of age or older"])},
      "Sign up to receive updates about TerraCycle and our programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in to receive email and SMS marketing information (optional)"])},
      "agree": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree to the terms and conditions.View our"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in a new browser window"])},
        
      ],
      "agree_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to all terms of use, collection and use of personal information, and reception(optional) of advertising information."])}
    }
  })
}
