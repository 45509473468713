export default {
  // Global
  'remark': '述べる',
  'more': 'もっと見る',
  'No': 'いいえ',
  'Apply': '申し込む',
  'Start': '始める',
  'Save': '保存する',
  'Return': '戻る',
  'Close': '閉じる',
  'Cancel': 'キャンセル',
  'Confirm': '確認',
  'Home': 'ホーム',
  'Request': '依頼',
  'All status': '全てのステータス',
  'Tips': 'Tips',
  'Pending': '保留中',
  'Approved': '参加中',
  'Cancelled': 'キャンセル済み',
  'Rejected': '失敗',
  'Closed': '退会済',
  'wait_listed': '順番待ちリスト',
  'Success': '成功',
  'Submit': '送信',
  'Submit success!': '成功しました!',
  'Operation success': '成功しました',
  'Please sign in': 'サインインしてください',
  'Sign in': 'サインイン',
  'Sign In': 'サインイン',
  'Sign Up': '新規登録する',
  'Sign In Partner': 'サインイン Partner',
  'The session has expired. Please log in again': 'セッションタイムアウトが発生しました。再度サインインしてください。',
  'Individual': '個人',
  'Group': '団体',
  'Please check the required': '必須項目を確認してください。',
  'No search results': '検索結果が存在しません。',
  'phoneNumberIsInValid': '電話番号 - ハイフンなし、半角英数字で入力してください。',
  'EMAIL ADDRESS': 'メールアドレス',
  'PHONE NUMBER': '電話番号',
  'PHONE NUMBER FORM LABEL': '電話番号（ハイフンなし、半角英数字で入力してください）',
  'PASSWORD': 'パスワード',
  'CONFIRM PASSWORD': 'パスワードを確認する',
  'VERIFICATION CODE': '認証コード',
  'Login success!': 'サインインが成功しました！',
  'termsOfUse': '利用規約',
  'operationPolicy': 'Operation Policy',
  'privacyPolicy': 'プライバシーポリシー',
  'mediaRoom': 'メディアルーム',
  'locationBased': 'Location-based service terms and conditions',
  'contactUs': 'お問い合わせ',
  'mine_page': 'マイページ',
  'Submit': '送信',
  'Yes': 'はい',
  'No': 'いいえ',
  'Please login': 'サインインしてください',
  'All': 'All',
  // menu
  'About': 'テラサイクルについて',
  'TerraCycle Intro': 'テラサイクルの紹介',
  'Strategic Partners': 'パートナー企業',
  'Program': 'リサイクルプログラム',
  'Views All Programs': '全てのプログラムを検索する',
  'Contests and Promotions': 'コンテストとプロモーション',
  'Shop': 'オンラインストア',
  'Story': 'ストーリー',
  'Zero Waste Box': 'ゼロウェイストボックス',
  'TC Select Shop': 'テラサイクル セレクト',
  'Contact Us': 'お問い合わせ',
  'Customer Service Center': 'カスタマー サービス センター',
  'Business inquiry': 'お問い合わせ',
  'Inquiry To Use': 'ご利用にあたってのお問い合わせ',
  'FAQ': 'よくある質問',
  'Inconsistent password input': 'パスワードが一致しません',
  // mine page menu
  'Member profile': '会員情報',
  'TC Point': 'テラサイクルポイント',
  'Wish list': '위시리스트',
  'My orders': '내 주문 내역',
  'Membership grade': '会員ランク',
  'My Program': 'マイプログラム',
  'Program participation record': '参加プログラム',
  'Membership ID with QR code': '会員ID QRコード',
  'Detail': '詳細',
  'My QR code': '会員コード',
  'Incentive Campaign List': 'インセンティブキャンペーン一覧',
  'My Coupon': 'マイクーポン',
  'PDO Dashboard': 'PDO ダッシュボード',
  'Participation history': '参加履歴',
  'Collection History': '出荷履歴/集荷依頼キャンセル ',
  'TC Subscribe': 'TC Subscribe',
  // shipment
  'Completed request': '完了済みの依頼',
  'Delivery': '配送',
  'Arrival': '到着',
  'Inspection': '検品',
  'Recycle': 'リサイクル',
  // gender
  'Male': '男性',
  'Female': '女性',
  'Prefer not to answer': '非回答',
  // chanel
  'Article': 'Article',
  'Website': 'ウェブサイト',
  'Friend': 'つながる',
  'Twitter': 'Twitter',
  'Facebook': 'Facebook',
  'Pinterest': 'Pinterest',
  'Instagram': 'Instagram',
  'Search': '検索',
  'Strategic Partnerships': 'パートナー企業',
  'Collection History': '出荷履歴/集荷依頼キャンセル ',
  // on going program
  'on_going_program_apply_success_content': '1箱分の集荷依頼を承りました。ありがとうございます。\n\n集荷予定日については、集荷日の前日までに別途メールでご連絡いたします。\n\n＊誤って依頼をしてしまった場合、マイページ「集荷履歴・キャンセル」より、依頼のキャンセルが可能です\n\n＊複数の箱の集荷をご希望される場合は、大変お手数ですが、個数分集荷依頼を実行くださいますようお願いいたします。\n\n例) 3箱の場合→3回集荷依頼ボタンをクリックくださいますようお願いいたします。',
  'on_going_program_apply_success_button': '戻る',
  'on_going_program_join_waiting_list_success_content': 'ウェイティングリストに登録されました！空きがでたらEメールでご連絡いたします。',
  'on_going_program_join_waiting_list_success_button': '確認',
  'on_going_program_error_button': '確認',
  // offline collection
  offline_collection: {
    back_to_home_page: 'ホームページに戻ります',
    done: '完了する',
    step_index: 'ステップ {index}',
    oc_collection_history: '拠点回収履歴',
    request_reward: '登録を続ける',
    oc_records: 'オフライン コレクション レコード',
    oc_reward_type: {
      points: 'ポイント',
      coupon: 'クーポン'
    },
    oc_reward_state: {
      init: '保留中のアプリケーション',
      pending: '審査待ち',
      approved: '承認済み',
      rejected: '却下',
    },
    form: {
      'Collected Bin QR code': '集めたビンのQRコード',
      'Scan membership code': '会員コードをスキャン',
      'Scan QR barcode on the collected item': '回収品のバーコードをスキャン',
      'Add more item': 'さらにアイテムを追加',
      'Please scan the membership code': '会員コードをスキャンしてください',
      'Membership code is invalid': '会員コードが無効です',
      'Please add barcode': 'バーコードを追加してください',
      'Submitted Successfully!': '正常に送信されました!',
      'We will email you once the rewards have been approved!': 'リクエストが承認されましたら、メールでお知らせします。',
      'Start Over': '最初からやり直す',
      'Check my OC records': '履歴を確認する'
    },
    record: {
      'Name': '名前',
      'Created at': '作成日',
      'Bin Name': '回収ボックス名',
      'Barcode': 'バーコード',
      'New Submission': '新規提出',
    },
  },
  redemption: {
    state: {
      requested: 'リクエスト',
      sent: '送信済',
      cancelled: 'キャンセル'
    }
  },
  participate: {
    join_content: '回収物の登録には、プログラムへの参加が必要です。プログラムに参加しますか？',
    join_success_content: 'プログラムへの参加を申請いただきありがとうございます。'
  }
};
