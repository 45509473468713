import { App } from 'vue';

export default {
  install(app: App) {
    // huggies有用到  暂时不能删
    app.component('ProgramApplyButton', require('./program/apply-button/index.vue').default);

    app.component('AppHeader', require('./header/index').default);
    app.component('AppFooter', require('./footer').default);
    app.component('AppCardVariant', require('./card-variant').default);
    app.component('AppCardProgram', require('./card-program').default);
    app.component('AppCardStory', require('./card-story').default);
    app.component('AppDialog', require('./dialog').default);
    app.component('AppOffcanvas', require('./offcanvas.vue').default);
    app.component('AppPageEditor', require('./custom/page-editor.vue').default);
    app.component('AppCustomPage', require('./custom/custom-page.vue').default);
    app.component('AppCustomPageContainer', require('./custom/custom-page-container.vue').default);
    app.component('AppSearch', require('./search.vue').default);
    app.component('AppFormGroupItem', require('./form-group-item').default);
    app.component('AppBreadcurmb', require('./breadcrumb.vue').default);
    app.component('AppBanner', require('./banner.vue').default);
    app.component('AppCollapse', require('./collapse.vue').default);
    app.component('AppGoogleMap', require('./google-map.vue').default);
    app.component('AppPagination', require('./pagination.vue').default);
    app.component('AppCarousel', require('./carousel.vue').default);
    app.component('AppCarouselWithController', require('./carousel-with-controller').default);
    app.component('AppDynamicComponent', require('./dynamic-component.vue').default);
    app.component('AppUserSurvey', require('./user-survey.vue').default);
    app.component('AppTimeFormat', require('./time-format.vue').default);
    app.component('AppFloat', require('./float/index.vue').default);
    app.component('AppEmptyView', require('./empty-view').default);
    app.component('AppVerificationCodeBtn', require('./verification-code-btn').default);
    app.component('AppCaptchaImage', require('./captcha-image').default);
    app.component('AppAutoHeight', require('./auto-height.vue').default);
    app.component('AppLoadingButton', require('./loading-button').default);
    app.component('AppTabs', require('./tabs/index.vue').default);
    app.component('AppTab', require('./tabs/tab.vue').default);
    app.component('AppCustomLink', require('./custom-link.vue').default);
    app.component('AppAddressAutoComplete', require('./address-autocomplete/index.vue').default);
    app.component('AppCountrySelector', require('./country-selector').default);
    app.component('AppNumberFormat', require('./number-format').default);
    app.component('AppMeta', require('./meta.vue').default);
    app.component('AppRichText', require('./rich-text/index.vue').default);
    app.component('AppSocialShare', require('./social-share.vue').default);
    app.component('AppScanner', require('./scanner.vue').default);
    app.component('AppPopover', require('./popover.vue').default);
    app.component('AppStepper', require('./stepper').default);
    app.component('AppScore', require('./score.vue').default);
    app.component('AppShop', require('./card-shop.vue').default);
    app.component('AppNavbar', require('./navbar.vue').default);
    app.component('AppRadio', require('./radio.vue').default);
    app.component('AppBack', require('./back.vue').default);
    app.component('AppPrice', require('./price.vue').default);
    app.component('AppCheckbox', require('./checkbox.vue').default);
    app.component('AppTextOverflow', require('./text-overflow.vue').default);
    app.component('AppSsoLoginPartner', require('./sso-login-partner.vue').default);
    app.component('AppIconScan', require('./icon-scan.vue').default);
    app.component('GoogleRecaptcha', require('./google-recaptcha.vue').default);
    app.component('AppArrowIcon', require('./arrow-icon.vue').default);
    app.component('AppTcSubscribePopup', require('./tc-subscribe-popup.vue').default);
  }
};
