export default {
  // Global
  'remark': 'Remark',
  'more': 'More',
  'No': 'No',
  'Apply': 'Apply',
  'Start': 'Start',
  'Save': 'Save',
  'Return': 'Return',
  'Close': 'Close',
  'Cancel': 'Cancel',
  'Confirm': 'Confirm',
  'Home': 'Home',
  'Request': 'Request',
  'All status': 'All status',
  'Tips': 'Tips',
  'Pending': 'Pending',
  'Approved': 'Approved',
  'Cancelled': 'Cancelled',
  'Rejected': 'Rejected',
  'Closed': 'Closed',
  'wait_listed': 'Wait Listed',
  'Success': 'Success',
  'Submit': 'Submit',
  'Submit success!': 'Submit success!',
  'Operation success': 'Operation success',
  'Please sign in': 'Please sign in',
  'Sign in': 'Sign in',
  'Sign In': 'Sign In',
  'Sign Up': 'Sign Up',
  'Sign In Partner': 'Sign in Partner',
  'The session has expired. Please log in again': 'The session has expired. Please log in again',
  'Individual': 'Individual',
  'Group': 'Group',
  'Please check the required': 'Please check the required',
  'No search results': 'No search results',
  'phoneNumberIsInValid': 'Phone number can only be entered with number',
  'EMAIL ADDRESS': 'EMAIL ADDRESS',
  'PHONE NUMBER': 'PHONE NUMBER',
  'PHONE NUMBER FORM LABEL': 'PHONE NUMBER',
  'PASSWORD': 'PASSWORD',
  'CONFIRM PASSWORD': 'CONFIRM PASSWORD',
  'VERIFICATION CODE': 'VERIFICATION CODE',
  'Login success!': 'Login success!',
  'termsOfUse': 'Terms of Use',
  'operationPolicy': 'Operation Policy',
  'privacyPolicy': 'Privacy Policy',
  'mediaRoom': 'Media Room',
  'locationBased': 'Location-based service terms and conditions',
  'contactUs': 'Contact Us',
  'mine_page': 'Mine Page',
  'Submit': 'Submit',
  'Inconsistent password input': 'Inconsistent password input',
  'Yes': 'Yes',
  'No': 'No',
  'Please login': 'Please login',
  'All': 'All',
  // menu
  'About': 'About',
  'TerraCycle Intro': 'TerraCycle Intro',
  'Strategic Partners': 'Strategic Partners',
  'Program': 'Program',
  'Views All Programs': 'Views All Programs',
  'Contests and Promotions': 'Contests and Promotions',
  'Shop': 'Shop',
  'Story': 'Story',
  'Zero Waste Box': 'Zero Waste Box',
  'TC Select Shop': 'TC Select Shop',
  'Contact Us': 'Contact Us',
  'Customer Service Center': 'Customer Service Center',
  'Business inquiry': 'Business inquiry',
  'Inquiry To Use': 'Inquiry To Use',
  'FAQ': 'FAQ',
  // mine page menu
  'Member profile': 'Member profile',
  'TC Point': 'TC Point',
  'Wish list': 'Wish list',
  'My orders': 'My orders',
  'Membership grade': 'Membership grade',
  'My Program': 'My Program',
  'Program participation record': 'Program participation record',
  'Membership ID with QR code': 'Membership ID with QR code',
  'Detail': 'Detail',
  'My QR code': 'My QR code',
  'Incentive Campaign List': 'Incentive Campaign List',
  'My Coupon': 'My Coupon',
  'PDO Dashboard': 'PDO Dashboard',
  'Participation history': 'Participation history',
  'Collection History': 'Collection History',
  'TC Subscribe': 'TC Subscribe',
  // shipment
  'Completed request': 'Completed request',
  'Delivery': 'Delivery',
  'Arrival': 'Arrival',
  'Inspection': 'Inspection',
  'Recycle': 'Recycle',
  // gender
  'Male': 'Male',
  'Female': 'Female',
  'Prefer not to answer': 'Prefer not to answer',
  // chanel
  'Article': 'Article',
  'Website': 'Website',
  'Friend': 'Friend',
  'Twitter': 'Twitter',
  'Facebook': 'Facebook',
  'Pinterest': 'Pinterest',
  'Instagram': 'Instagram',
  'Search': 'Search',
  'Strategic Partnerships': 'Strategic Partnerships',
  'Collection History': 'Collection History',
  // on going program
  'on_going_program_apply_success_content': 'Participate in success',
  'on_going_program_apply_success_button': 'Close',
  'on_going_program_join_waiting_list_success_content': 'The current number of applicants is full and you have been added to the shortlist. We will notify you by email when we can rejoin.',
  'on_going_program_join_waiting_list_success_button': 'OK',
  'on_going_program_error_button': 'Close',
  // offline collection
  offline_collection: {
    back_to_home_page: 'Back to home page',
    done: 'I’m Done',
    step_index: 'Step {index}',
    oc_collection_history: 'Offline Collection History',
    request_reward: 'Request',
    oc_records: 'Offline Collection Records',
    oc_reward_type: {
      points: 'Points',
      coupon: 'coupon'
    },
    oc_reward_state: {
      init: 'Pending Application',
      pending: 'Pending Review',
      approved: 'Approved',
      rejected: 'Rejected',
    },
    form: {
      'Collected Bin QR code': 'Collected Bin QR code',
      'Scan membership code': 'Scan membership code',
      'Scan QR barcode on the collected item': 'Scan QR barcode on the collected item',
      'Add more item': 'Add more item',
      'Please scan the membership code': 'Please scan the membership code',
      'Membership code is invalid': 'Membership code is invalid',
      'Please add barcode': 'Please add barcode',
      'Submitted Successfully!': 'Submitted Successfully!',
      'We will email you once the rewards have been approved!': 'We will email you once the rewards have been approved!',
      'Start Over': 'Start Over',
      'Check my OC records': 'Finished! Check my offline collection records'
    },
    record: {
      'Name': 'Name',
      'Created at': 'Created at',
      'Bin Name': 'Bin Name',
      'Barcode': 'Barcode',
      'New Submission': 'New Submission',
    }
  },
  redemption: {
    state: {
      requested: 'Request received',
      sent: 'Sent',
      cancelled: 'Cancelled'
    }
  },
  participate: {
    join_content: 'You are required to join the program to register the drop off item. Do you wish to join the program?',
    join_success_content: 'Thank you for applying to the program.'
  }
};
